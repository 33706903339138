import { Injectable, EventEmitter, Inject, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import localePt from '@angular/common/locales/pt';
import localeSv from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
import { DatepickerI18nService } from './datepicker-i18n.service';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
registerLocaleData(localeIt);
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeDe);
registerLocaleData(localePt);
registerLocaleData(localeSv);

export enum CulturesKey {
	IT = "it",
	EN = "en",
	es = "es",
	DE = "de",
	PT = "pt",
	FR = "fr",
	SV = "sv"
}

@Injectable()
export class CultureService {
	public static DEFAULT_LANGUAGE = "en";

	public cultureChanged: EventEmitter<string> = new EventEmitter<string>();
    private cultureMapping = {
        'it': localeIt,
        'en': localeEn,
        'es': localeEs,
		'de': localeDe,
		'pt': localePt,
		'sv': localeSv
    }
    constructor(private _translateService: TranslateService, private _datepickerI18nService: NgbDatepickerI18n, @Inject(CULTURE_CONFIGURATION) private _cultureConfiguration: ICultureConfiguration) {
        this.configure(this._cultureConfiguration.cultures, this._cultureConfiguration.defaultCultureCode);

    }

    public configure = (cultures: CultureInfo[], currentCulture: string) => {
        this._translateService.addLangs(cultures.map(m => m.code));
        this._translateService.setDefaultLang(currentCulture);
        this.setCurrentCulture(currentCulture);
        setTimeout(() => {
            registerLocaleData(this.cultureMapping[currentCulture]);
        }, 100);
    }

    public getCurrentCulture = (): CultureInfo => {
        return this._cultureConfiguration.cultures.find(f => f.code == this._translateService.currentLang);
    }

    public setCurrentCulture = (culture: string): void => {

        // Label
        this._translateService.use(culture);

        // Datepicker
        (<DatepickerI18nService>this._datepickerI18nService).changeLocale(culture);

        //Moment
        moment.locale(culture)
        this.cultureChanged.emit(culture);
    }

	getCultureInfo(keys: string[]) {
		return defaultCultureConfiguration.cultures.filter((value) => {if (keys.contains(value.code))return value;})
	}
}

export class CultureInfo {
    constructor(public code: string, public description: string) {
    }
}

export interface ICultureConfiguration {
    cultures: CultureInfo[];
    defaultCultureCode: string;
}

export const CULTURE_CONFIGURATION = new InjectionToken<ICultureConfiguration>('ICultureConfiguration');

export const defaultCultureConfiguration: ICultureConfiguration = {
    cultures:[
		new CultureInfo("it", "Italiano"),
		new CultureInfo("en", "English"),
		new CultureInfo("es", "Espanol"),
		new CultureInfo("fr", "French"),
		new CultureInfo("de", "Deutsch"),
		new CultureInfo("sv", "svenska")
	],
    defaultCultureCode: CultureService.DEFAULT_LANGUAGE
};
