import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

class Group {
	id: any;
	secureId?: string;
	icon?: string;
	items: any[];
	subgroups: Group[];
}

@Pipe({
	name: 'filteringredient'
})
export class FilterIngredientPipe implements PipeTransform {

	constructor(private _translate: TranslateService) {}

	transform(items: Group[], searchTerm: string): any {
		
		if (!items) {
			return [];
		}
		if (!searchTerm || searchTerm.trim() == "") return items;
		let search = searchTerm.toLowerCase()
		let result = [];
		// items.forEach((item) => {
		// 	if (item.id.toLowerCase().includes(search)) {
		// 		result.push(item);
		// 	}
		// 	else {
		// 		let tmp: Group = {
		// 			id: item.id,
		// 			icon: item.icon,
		// 			secureId: item.secureId,
		// 			items: item.items.filter((f) => {return f.toLowerCase().includes(search)}),
		// 			subgroups: []
		// 		}
		// 		item.subgroups.forEach(element => {
		// 			if (element.id.toLowerCase().includes(search)) {
		// 				tmp.subgroups.push(element);
		// 			}
		// 			else {
		// 				let tmp2 = {
		// 					id: element.id,
		// 					icon: element.icon,
		// 					secureId: element.secureId,
		// 					items: element.items.filter((f) => {return f.toLowerCase().includes(search)}),
		// 					subgroups: []
		// 				}
		// 				if (tmp2.items.length > 0) tmp.subgroups.push(tmp2);
		// 			}
		// 		});
		// 		if (tmp.items.length > 0 || tmp.subgroups.length > 0) result.push(tmp);
				
				
		// 	}
		// })
		items.forEach((item) => {
			
			let tmp: Group = {
				id: item.id,
				icon: item.icon,
				secureId: item.secureId,
				items: item.items.filter((f) => {
					let translated = ""+this._translate.instant(f.id).toLowerCase();
					return translated.includes(search);
				}),
				subgroups: []
			}
			item.subgroups.forEach(element => {
				// if (element.id.toLowerCase().includes(search)) {
				// 	tmp.subgroups.push(element);
				// }
				// else {
					let tmp2 = {
						id: element.id,
						icon: element.icon,
						secureId: element.secureId,
						items: element.items.filter((f) => {
							let translated = ""+this._translate.instant(f.id).toLowerCase();
							return translated.includes(search);
						}),
						subgroups: []
					}
					if (tmp2.items.length > 0) tmp.subgroups.push(tmp2);
				// }
			});
			if (tmp.items.length > 0 || tmp.subgroups.length > 0) result.push(tmp);
		});

		return result;
		// return items.filter(
		// 	item => {

		// 		if (labelKeys) {
		// 			for (let i = 0; i < labelKeys.length; i++) {
		// 				if ((""+item[labelKeys[i]]).toLowerCase().includes(search) === true) return true;
		// 			}
		// 		}
		// 		return false;
		// 	}
		// );
	}
}
