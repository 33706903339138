import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupItem } from 'app/services/ingrediant.service';
import { PopupInfoComponent } from '../popup-info/popup-info.component';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';


@Component({
	selector: 'item-details',
	templateUrl: './item-details.component.html',
	styleUrls: ['item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit, AfterViewInit{
	@Input("item") item: GroupItem;
	@Input("showItemName") showItemName: boolean = false;
	pageLoaded = false;


	get itemId () {
		return (this.item)? this.item.id: "";
	}
	constructor(
		private _modalService: ModalService
	) {
		
	}

	static index = 0;
	classes = ["A", "B", "C", "D", "E"];
	get itemEnvScoreClass() {
		return (this.item)? this.item.environmentalScore: "A";
	}
	get itemNutriscoreClass() {
		// return this.classes[ItemDetailsComponent.index++ % 5];
		return "C"
	}
	ngAfterViewInit(): void {
		
	}

	get dietaryReccomendation() {
		return (this.item)? "Recommendation_on_Sustainability_and_Nutritional."+this.item.Recommendation_on_Sustainability_and_Nutritional : "";

	}
	get frequencyOfConsumption() {
		return (this.item)? "frequencyOfConsumption." + this.item.frequencyOfConsumption : "";

	}

	itemName
	ngOnInit() {
		// this._activatedRoute.queryParams.subscribe(queryParams => {
		// 	console.log("queryParams",queryParams);
		// 	let parsed = JSON.parse(queryParams.item);
		// 	this.itemName = (parsed.id)? parsed.id: queryParams.item;
		// 	// this.companyId = queryParams.get("companyId");
		// 	// let toCall: any = {
		// 	// }
			
		// 	// toCall["product"] = this._productService.getByField( queryParams.get("companyId"), "productId", queryParams.get("productId"));
		// 	// toCall["company"] = this._companyService.getCompanyByField("companyId", queryParams.get("companyId"));
		// 	// toCall["userProfile"] = this._profileService.getLoggedProfile();

		// 	// forkJoin(toCall).subscribe((data) => {
		// 	// 	this.productDefinition = data["product"];
		// 	// 	this.company = data["company"];
		// 	// 	this.userProfile = data["userProfile"];
		// 	// 	this.productDefinition.linkedEntity.forEach((entity) => {
		// 	// 		if (entity.entityId == queryParams.get("entityType")) {
		// 	// 			this.referredEntity = entity;
		// 	// 		}
		// 	// 	})
				
			
		// 	// });

			

		// });
		this.pageLoaded = true;
	}

	showEnvScore = false;
	showHideEnvScore = () => {
		this.showEnvScore = !this.showEnvScore;
	}
	

	showNutriScore = false;
	showHideNutriScore = () => {
		this.showNutriScore = !this.showNutriScore;
	}

	showInfo = (infoId) => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {}
		}
		let data = {
			sectionId: infoId
		}
		this._modalService.show(PopupInfoComponent, data, modalOption);
	}
}